import React, {useEffect, useState} from 'react';
import configData from "../config.json";
import { useForm } from "react-hook-form";

const AdminLogin = () => {

    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);


  const adminUser = (data) => {

    setDisablebtn(true);


    return fetch(`${configData.SERVER_URL}/admin/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({            
            email:data.email,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){
            window.localStorage.setItem("Admin", responseJson.admin.role);
            window.localStorage.setItem("token", responseJson.token);
            window.location = window.location.origin + "/Admin/Dashboard";
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
            alert(responseJson.message);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });
}

    return (


    <div style={{backgroundColor:"#fff", height:'100vh'}}> 

        <div class="col-md-12 h-100"> 

         <div class="row">
             <div class="col-md-3"></div>
             <div class="col-md-6 mt-4">
                    <div class="col-md-12 mt-5">                                        
                   
                        <form onSubmit={handleSubmit(adminUser)} enctype="multipart/form-data" id="submit" class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                               {/* <div class="alert alert-warning" role="alert">
                                   {message.length ? ({message}) : (null)}
                                    </div>        */}
                            <p class="h4 mb-4">Welcome Back</p>    
                            <div class="form-group">
                                <input type="email" id="FormEmail" class="form-control mb-4" placeholder="Email" name="email" ref={register({ required: true, })} />
                                {errors.email && <div class="alert alert-danger" role="alert">Email Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" >
                                              {disablebtn ? (<>Please wait</>) : (<>LOGIN</>)}
                                        </button>
                                </div>

                        </form>
                    </div>
                </div>
             <div class="col-md-3"></div>
           </div> 

         </div>



        </div>




    );
  

}

export default AdminLogin;