import React, { useState, useEffect, useStickyState } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import AddProduct from "../Admin/component/AddProduct";
import { format } from 'date-fns' 

const Product = () => {
  const [product, setProduct] = useState([])
  const [productImage, setProductImage] = useState('');
  const [isBtnLoading, setisBtnLoading] = useState(false);
  const [prodId, setProdId] = useState('')

  const updateProd = async(e) => {
        setisBtnLoading(true);

        e.preventDefault()
        
        const formData = new FormData()
        formData.append('productImage', productImage)
      
            return fetch(`${configData.SERVER_URL}/product/update/${prodId}`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "x-auth-token":  window.localStorage.getItem("token")
                },
                body:formData
            })
            .then((response) => response.json())
            .then((responseJson) => {
      
                if(responseJson.status === "success"){
                  setisBtnLoading(false);
                  window.$("#modal3").modal("hide") 
                    alert(responseJson.message);
                }
                if (responseJson.status === "error") {
                    console.log(responseJson.message);
                }
            })
            .catch((error) => {
              setisBtnLoading(false);
                console.error(error);
            });
  
  }

  const deleteProd = async() => {

          setisBtnLoading(true);

        return fetch(`${configData.SERVER_URL}/product/delete/${prodId}`, {
            method: "delete",
            headers: {
                Accept: "application/json",
                "x-auth-token":  window.localStorage.getItem("token")
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {

            if(responseJson.status === "success"){
                setisBtnLoading(false);
                window.$("#modal3").modal("hide") 
                alert(responseJson.message);
            }
            if (responseJson.status === "error") {
                alert(responseJson.message);
            }
        })
        .catch((error) => {
          setisBtnLoading(false);
            console.error(error);
        });

  }

  const getProduct = async() => {
         
    return fetch(`${configData.SERVER_URL}/product`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          setProduct(responseJson.product);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    const interval=setInterval(()=>{
      getProduct();
     },3000)
      
     return()=>clearInterval(interval)
 },[]);

 const editProduct = (product) => {
     setProdId(product._id);

     window.$("#modal3").modal("show") 

 }

    return (

		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
        <AddProduct/>
    
    

       {/* geting the categories */}
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL PRODUCTS</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

                  <div className="row">
                    {product.map(p => (<>
                            <div class="col-md-3 mt-2">
                                <div class="boxShadow p-2">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <img class="img-fluid d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`} onClick={()=> editProduct(p)}/>
                                            </div>
                                        <div class="col-lg-12 mt-1">
                                            <h6>{p.name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>))}


      <div className="modal fade" id="modal3" tabindex="-1" role="dialog"
                           aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="modalLabel">Update Product</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12"  onSubmit={updateProd} method="POST" enctype="multipart/form-data" id="submit" >
                                  <div class="form-group">
                                    <label for="exampleFormControlFile1">Upload Item Image</label>
                                    <input type="file" class="form-control-file" name="productImage"
                                    onChange={(e)=> setProductImage(e.target.files[0])}/>
                                  </div>
                                  <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>UPDATE PRODUCT</>)}</button>
                                </form>
                                
                                <span onClick={deleteProd} class="btn btn-warn btn-block btn-sm mt-4" style={{color: 'red'}} disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>DELETE PRODUCT</>)}</span>
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        

      );

  }
  


export default Product;
