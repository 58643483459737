import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Sidebar'
import configData from "../../config.json";
import AddCity from "./component/AddCity";
import AddArea from "./component/AddArea";
import AddGroup from "./component/AddGroup";
import { format } from 'date-fns' 

const Location = () => {
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [group, setGroup] = useState([]);

  //  let { id } = useParams();

  const getCity = () => {
       
    return fetch(`${configData.SERVER_URL}/state`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setCity(responseJson.state);
        })
        .catch((error) => {
          console.error(error);
        });
  }


  const getGroup = () => {
       
    return fetch(`${configData.SERVER_URL}/group`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setGroup(responseJson.group);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getAreas = ()=>{
       
    return fetch(`${configData.SERVER_URL}/area`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setArea(responseJson.area);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    getCity();
    getGroup();
    getAreas();
 },[]);

    return (
  
	<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
    <div id="content" class="p-4 p-md-5">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-primary">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars"></i>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                  <AddCity/>
              </li>
              <li class="nav-item active">
                  <AddGroup/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
        
    <div className="col-md-12 mt-4">
        <div className="row">
          <div className="col-md-12">
            <p className="title2">ALL LOCATION</p>
          </div>
          <div className="col-md-12">
            <div className="row">


<div className="col-md-6 mt-3">

<div className="table-responsive">
  <table className="table white black-text table-hover">
  <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">City Name</th>
            <th scope="col">Date Created</th>
            </tr>
        </thead>
      <tbody>
       {city.map(c => (
            <tr className="" key={c._id}>
              <td></td>
              <td>{c.name}</td>
              <td>{format(new Date(c.createdAt), 'MMMM, do, yyy')}</td>
              {/* <td>
                <Link to={`/`}>
                  <i className="fa fa-edit mr-2" aria-hidden="true"></i>
                </Link>
              </td> */}
            </tr>
       ))}

      </tbody>
  </table>
</div>
</div>


<div className="col-md-6 mt-3">

<div className="table-responsive">
  <table className="table white black-text table-hover">
  <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Group Name</th>
            <th scope="col">Group State</th>
            <th scope="col">Date Created</th>
            </tr>
        </thead>
      <tbody>
       {group.map(c => (
            <tr className="" key={c._id}>
              <td></td>
              <td>{c.name}</td>
              <td>{c.stateName}</td>
              <td>{format(new Date(c.createdAt), 'MMMM, do, yyy')}</td>
              <td>
                {/* <Link to={`/`}>
                  <i className="fa fa-edit mr-2" aria-hidden="true"></i>
                </Link> */}
              </td>
            </tr>
       ))}

      </tbody>
  </table>
</div>
</div>

            </div>
          </div>
        </div>
      </div>

   </div>
   </div>
   
   
    );

}



export default Location;