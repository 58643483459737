import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import { format } from 'date-fns' 

const BrickMDetails = () => {
  const [trans, setTrans] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [brick, setBrick] = useState([])
  const [page, setPage] = useState('product');
  const [vendor, setVendor] = useState([]);
  const [product, setProduct] = useState([])

  //  let { id } = useParams();
    const id =   window.localStorage.getItem("morterId");

  
    const getBricks = async() => {
      setIsLoading(true)
            
        return fetch(`${configData.SERVER_URL}/vendor/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        })
        .then((response) => response.json())
        .then((responseJson) => {
          setVendor(responseJson.data);
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error(error);
        });
  
    }
  
    const getProduct = async() => {
      
      return fetch(`${configData.SERVER_URL}/vendorProducts/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            setProduct(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }
  

    const transaction = async() => {
          
        return fetch(`${configData.SERVER_URL}/transaction/admin/mortarTrans/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setTrans(responseJson.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });

    };

    useEffect(() => {
      getBricks()
      getProduct()
      
      const interval=setInterval(()=>{
        transaction();
      },3000)
        
      return()=>clearInterval(interval)

    },[]);


    const viewMore = (trans) => {
        window.localStorage.setItem("refNumb", trans.reference)
        window.localStorage.setItem("tranId", trans._id)
        window.location = window.location.origin + "/Admin/TransactionDetials" 
      }


    return (
    
		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">

          <div className="d-flex align-items-center mt-4 mb-4">
			      <img src={`${configData.PIC_URL}/${vendor.logo}`} className="img-fluid img-thumbnail tableImg" alt="Sheep"/>
             <h4 className="ml-3">{vendor.name}</h4>
          </div>

          <span onClick={() => setPage('product')} className='ml-4' style={{cursor:"pointer", fontSize:"16px"}}>Products</span>
          <span onClick={() => setPage('trans')}  className='ml-4' style={{cursor:"pointer", fontSize:"16px"}}>Transactions</span>
    
    {page === "product" ? 
          <div className="col-md-12 mt-4">
          <div className="col-md-12">
            <p className="title2">PRODUCTS</p>
          </div>
              <div className="row">
                    {product.map(p => (<>
                            <div class="col-md-3 mt-2">
                                <div class="boxShadow p-2">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <img class="img-fluid d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`}/>
                                            </div>
                                        <div class="col-lg-12 mt-1">
                                            <h6>{p.name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>))}



              </div>
          </div>
        :
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">TRANSACTIONS</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

          {isLoading ? <>Loading </> : <>
                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            <tr>
                            <th scope="col">Users Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Vendors Name</th>
                            <th scope="col">Agents Name</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Payment Status</th>
                            </tr>
                        </thead>
                      <tbody>
                 {trans.map(t => (
                            <tr className="" key={t._id}>
                              <td>{t.userName}</td>
                              <td>₦ {t.amount}</td>
                              <td>{t.vendorName}</td>
                              <td>{t.agentName}</td>
                              <td>{t.orderStatus}</td>
                              <td>{t.orderPaymentStatus}</td>
                              <td>{format(new Date(t.createdAt), 'MMMM, do, yyy')}</td>                             
                            <td onClick={() => viewMore(t)} style={{cursor:'pointer', backgroundColor:'green', color:'#fff'}}>view</td>
                            </tr>
                     ))}

                      </tbody>
                    </table>
                  </div>
                  </>}


                </div>
              </div>
            </div>
          </div>
        </div>

            }


        </div>
      </div>
        
    
    );

  }

export default BrickMDetails;
