import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import { format } from 'date-fns' 
import AddVendor from "./component/AddVendor";
import { useForm } from "react-hook-form";

const Vendors = () => {
  const [user, setUser] = useState([])
  const { register, handleSubmit, watch, errors } = useForm();
  const [fullname, setFullName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [groupId, setGroupId] = useState();
  const [isBtnLoading, setisBtnLoading] = useState(false);
  const [group, setGroup] = useState([]);
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getGroup = ()=>{
     
    return fetch(`${configData.SERVER_URL}/group`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setGroup(responseJson.group);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    getGroup();
 },[]);


  //  let { id } = useParams();

  const agentModal = (d) => {
      setUserId(d._id)
      setGroupId(d.userGroupId)
      setFullName(d.fullname)
      setEmail(d.email)
      setPhone(d.phone)
    window.$("#agentModal").modal("show"); 
  }

  const updateAg = () => {

      setisBtnLoading(true)
    
    //  const userGroupId = data.groupId; 
      return fetch(`${configData.SERVER_URL}/users/updateUserDetails/${userId}`, {
          method: "post",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token":  window.localStorage.getItem("token")
          },
          body: JSON.stringify({            
              fullname:fullname,      
              email:email,  
              phone:phone, 
              userGroupId:groupId
          })
      })
      .then((response) => response.json())
      .then((responseJson) => {
  
          if(responseJson.status === "success"){
              setisBtnLoading(false)
              alert(responseJson.message);
          }
          if (responseJson.status === "error") {
              setisBtnLoading(false)
              alert(responseJson.message);
          }
      })
      .catch((error) => {
          setisBtnLoading(false)
          console.error(error);
      });
  
  
  
  }

  const agentDeactivate = (u) => {
    if (!window.confirm("are you sure?")) {
      return
    }
            
   const id = u._id;
    return fetch(`${configData.SERVER_URL}/users/delete/${id}`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.message)
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const agentActivate = (u) => {
    if (!window.confirm("are you sure?")) {
      return
    }
            
   const id = u._id;
    return fetch(`${configData.SERVER_URL}/users/agentActivate/${id}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.message)
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const agentDelete = (u) => {
    if (!window.confirm("are you sure?")) {
      return
    }
            
   const id = u._id;
    return fetch(`${configData.SERVER_URL}/users/delete/${id}`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.message)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //  let { id } = useParams();

  const getVendors = () =>{
      
    return fetch(`${configData.SERVER_URL}/users/vendors`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.users)
        setUser(responseJson.users);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
   
    const interval=setInterval(()=>{
      getVendors();
     },3000)
      
     return()=>clearInterval(interval)

 },[]);



    return (<>
    
		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
     
       <AddVendor/>

        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL VENDORS</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">


                {isLoading ? <>Loading </> : <>   

                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            </tr>
                        </thead>
                      <tbody>
                 {user.map(u => (
                            <tr className="" key={u._id}>
                              <td></td>
                              <td>{u.fullname}</td>
                              <td>{u.email}</td>
                              <td>{u.phone}</td>
                              {/* <td>{format(new Date(u.createdAt), 'MMMM, do, yyy')}</td>                              */}
                               <td>{u.userGroupName}</td>
                              {(u.isActive === true) && <>
                              <td>
                                <Link onClick={()=> agentDeactivate(u)} style={{backgroundColor:'#f8b739', padding:5, borderRadius:20, color:'#fff'}}>
                                  deactivate
                                </Link>
                              </td>
                              </>}
                              {(u.isActive === false) && <>
                              <td>
                                <Link  onClick={()=> agentActivate(u)}style={{backgroundColor:'green', padding:5, borderRadius:20, color:'#fff'}}>
                                  activate
                                </Link>
                              </td>  
                              </>}                          
                              <td>
                                <Link onClick={()=> agentModal(u)}>
                                  <i className="fa fa-edit mr-2" aria-hidden="true" style={{color:'green'}}></i>
                                </Link>
                              </td> 
                              <td>
                                <Link  onClick={()=> agentDelete(u)}>
                                  <i className="fa fa-trash mr-2" aria-hidden="true" style={{color:'red'}}></i>
                                </Link>
                              </td>
                            </tr>
                     ))}

                      </tbody>
                    </table>
                  </div>

                  </>}

                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        
      <div className="modal fade" id="agentModal" tabindex="-1" role="dialog"
                aria-labelledby="agentModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="agentModalLabel">Update Agent</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                              <form class="col-12" onSubmit={handleSubmit(updateAg)}>
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp"  value={fullname}  onChange={(e) => setFullName(e.target.value)}  name="fullname" />
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" value={email} onChange={(e) => setEmail(e.target.value)} name="email"/>
                                  </div>
                                  <div class="form-group">
                                    <input type="tel" class="form-control" aria-describedby="textHelp" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone"/>
                                  </div>
                                <div className="mb-2">
                                    {group.map(b => {
                                        if(b._id === groupId) 
                                            return <label for="exampleFormControlSelect1">Vendors Current Group is {b.name}</label>
                                            })}
                                </div>
                                  <div class="form-group">
                                      <label for="exampleFormControlSelect1">Select Group</label>
                                      <select class="form-control"  name="groupId"  onChange={(e) => setGroupId(e.target.value)}>
                                        {(!group.length) ? (null) : (<>
                                            {group.map(s => (
                                                <option value={s._id}>{s.name}</option>
                                            ))}
                                        </>)}
                                      </select>
                                  </div>
                                  <button  type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>UPDATE VENDOR</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
       
    
   </> );

  }

export default Vendors;
