import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";

function NavBar() {
  const [isloading, setIsloading] = useState(false);
  const [isBtnLoading, setisBtnLoading] = useState(false);

  
  return (
   <>


      <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <button type="button" id="sidebarCollapse" class="btn btn-primary">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Toggle Menu</span>
        </button>
        <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="nav navbar-nav ml-auto">
            {/* <li class="nav-item active">
                <a class="nav-link" href="#">Add Category</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Portfolio</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Contact</a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>

    
</>

  );
}

export default NavBar;
