import React, { useState, useEffect } from 'react';
import configData from "../../../config.json";
import { useForm } from "react-hook-form";


const AddArtisan = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [city, setCity] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [msg, setMsg] = useState("")

 const createCat = (data) => {

            //  return console.log(data.name)
             
            setisBtnLoading(true);
      
            return fetch(`${configData.SERVER_URL}/artisanCategory`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-auth-token":  window.localStorage.getItem("token")
                },
                body:JSON.stringify({            
                  name:data.name,
              })
            })
            .then((response) => response.json())
            .then((responseJson) => {
      
                if(responseJson.status === "success"){
                    setisBtnLoading(false);
                    alert(responseJson.message);
                }
                if (responseJson.status === "error") {
                    setMsg(responseJson.message);
                    console.log(responseJson.message);
                }
            })
            .catch((error) => {
              setisBtnLoading(false);
                console.error(error);
            });
   
  }


    return (
      <>

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-primary">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars"></i>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                  <a class="nav-link" href="#"  data-toggle="modal" data-target="#modal3">Add Category</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="modal fade" id="modal3" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Add Artisan</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <span className="">{msg}</span>
                                <div className="row">
                                <form class="col-12" onSubmit={handleSubmit(createCat)} enctype="multipart/form-data" id="submit" >
                                
                                   
                                    <div class="form-group">
                                        <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Category name" name="name" ref={register({ required: true, })} />
                                       {errors.name && <div class="alert alert-danger" role="alert">Category name required</div>}
                                    </div>

                                    <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>SUBMIT</>)}</button>
                                    
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
       </>  );

  }

export default AddArtisan;