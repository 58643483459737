import React, { useState, useEffect, useStickyState } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import AddBrickMorta from "../Admin/component/AddBrickMorta";
import { format } from 'date-fns' 

const BrickMorta = () => {
  const [vendors, setVendors] = useState([])
  const [productImage, setProductImage] = useState('');
  const [isBtnLoading, setisBtnLoading] = useState(false);
  const [prodId, setProdId] = useState('')

const updateProd = async(e) => {
      setisBtnLoading(true);

      e.preventDefault()
      
      const formData = new FormData()
      formData.append('productImage', productImage)
    
          return fetch(`${configData.SERVER_URL}/product/update/${prodId}`, {
              method: "post",
              headers: {
                  Accept: "application/json",
                  "x-auth-token":  window.localStorage.getItem("token")
              },
              body:formData
          })
          .then((response) => response.json())
          .then((responseJson) => {
    
              if(responseJson.status === "success"){
                setisBtnLoading(false);
    
                  alert(responseJson.message);
              }
              if (responseJson.status === "error") {
                  console.log(responseJson.message);
              }
          })
          .catch((error) => {
            setisBtnLoading(false);
              console.error(error);
          });
 
}

  const getVendors = async() => {
         
    return fetch(`${configData.SERVER_URL}/vendor`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          setVendors(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    // const interval=setInterval(()=>{
      getVendors();
    //  },3000)
      
    //  return()=>clearInterval(interval)
 },[]);

 const editProduct = (product) => {
     setProdId(product._id);

     window.$("#modal3").modal("show") 

 }

 const deleteVendor = async(vendor) => {
            
    if (!window.confirm("are you sure you want to delete this vendor?")) {
      return
    }
        setisBtnLoading(true);

        return fetch(`${configData.SERVER_URL}/vendor/delete/${vendor._id}`, {
            method: "delete",
            headers: {
                Accept: "application/json",
                "x-auth-token":  window.localStorage.getItem("token")
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {

            if(responseJson.status === "success"){
                setisBtnLoading(false);
                window.$("#modal3").modal("hide") 
                getVendors();
                alert(responseJson.message);
            }
            if (responseJson.status === "error") {
                alert(responseJson.message);
            }
        })
        .catch((error) => {
          setisBtnLoading(false);
            console.error(error);
        });

 }


 const viewMore = (v) => {
  window.localStorage.setItem("morterId", v._id);
  window.location = window.location.origin + "/Admin/Morta/Details" 
}


    return (

		<div class="wrapper d-flex align-items-stretch">
            
            <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
            <AddBrickMorta/>
    
    

       {/* geting the categories */}
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">Brick and Morta Vendors</p>
            </div>
            <div className="col-md-12">
              <div className="row">



    <div class="col-12 mt-3">
		<table className="table table-image">
		  <thead>
		    <tr>
		      <th scope="col">No</th>
		      <th scope="col">Logo</th>
		      <th scope="col">Brick & Mortar Name</th>
		      <th scope="col">Email</th>
		      <th scope="col">Phone</th>
		      <th scope="col">State</th>
          <th scope="col"></th>
		    </tr>
		  </thead>
		  <tbody>

        {vendors && vendors.length > 0
            ?  vendors.map((v, index) => {
            return <>
		    <tr>
		      <th scope="row">{index + 1}</th>
		      <td>
			      <img src={`${configData.PIC_URL}/${v.logo}`} className="img-fluid img-thumbnail tableImg" alt="Sheep"/>
		      </td>
		      <td>{v.name}</td>
		      <td>{v.email}</td>
		      <td>{v.phone}</td>
		      <td>{v.state}</td>
          <td> 
            <span onClick={()=> deleteVendor(v)}>
                <i className="fa fa-trash mr-2"></i>
              </span>
           </td>
          <td> 
            <span onClick={()=> viewMore(v)}>
                <i className="fa fa-ellipsis-h mr-2"></i>
              </span>
           </td>
		    </tr>

            </>})
            : <div className="col-md-12"> 

                <h6>No item found</h6>
                
                </div>}
		  </tbody>
		</table>   
    </div>



      <div className="modal fade" id="modal3" tabindex="-1" role="dialog"
                           aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="modalLabel">Update Product</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12"  onSubmit={updateProd} method="POST" enctype="multipart/form-data" id="submit" >
                                  <div class="form-group">
                                    <label for="exampleFormControlFile1">Upload Item Image</label>
                                    <input type="file" class="form-control-file" name="productImage"
                                    onChange={(e)=> setProductImage(e.target.files[0])}/>
                                  </div>
                                  <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD PRODUCT</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        

      );

  }
  


export default BrickMorta;
