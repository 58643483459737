import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from "./apps/Admin/Dashboard";
import Category from "./apps/Admin/Category";
import Location from "./apps/Admin/Location";
import Product from "./apps/Admin/Product";
import User from "./apps/Admin/Users";
import Admin from "./apps/Admin/Admin";
import Agents from "./apps/Admin/Agents";
import AdminLogin from "./apps/Login";
import Area from "./apps/Admin/Area";
import Deals from "./apps/Admin/Deals";
import Vendors from "./apps/Admin/Vendors";
import Transactions from "./apps/Admin/Transactions";
import TransactionDetials from "./apps/Admin/TransactionDetails";
import BrickMorta from "./apps/Admin/Brick-add-Morta";
import Artisan from "./apps/Admin/Artisan";
import BrickMDetails from "./apps/Admin/BrickMDetails"

function App() {
  return (
    <Router>
       <Switch>

       <Route path="/" exact>
            <AdminLogin />
          </Route>
          <Route path="/Admin/Dashboard">
            <Dashboard />
          </Route>
          <Route path="/Admin/Deals">
            <Deals />
          </Route>
          <Route path="/Admin/Category">
            <Category />
          </Route>
          <Route path="/Admin/Location">
            <Location />
          </Route>
          <Route path="/Admin/Product">
            <Product />
          </Route>
          <Route path="/Admin/Users">
            <User />
          </Route>
          <Route path="/Admin/Admins">
            <Admin />
          </Route>
          <Route path="/Admin/Agents">
            <Agents />
          </Route>
          <Route path="/Admin/Vendors">
            <Vendors />
          </Route>
          <Route path="/Admin/Brick-add-Morta">
            <BrickMorta />
          </Route>
          <Route path="/Admin/Morta/Details">
            <BrickMDetails />
          </Route>
          <Route path="/Admin/Artisans">
            <Artisan />
          </Route>
          <Route path="/Admin/Area">
            <Area />
          </Route>
          <Route path="/Admin/Transactions">
            <Transactions />
          </Route>
          <Route path="/Admin/TransactionDetials">
             <TransactionDetials/>
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
