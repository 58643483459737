import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import Sidebar from "../Sidebar";
import NavBar from "../NavBar";

function Dashboard() {
  return (
   
		<div class="wrapper d-flex align-items-stretch">
    <Sidebar/>


    <div id="content" class="p-4 p-md-5">

    <NavBar/>

      <h2 class="mb-4">DASHBOARD</h2>
      
    </div>
  </div>
  );
}

export default Dashboard;
