import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../../config.json";
import { format } from 'date-fns';
import { useForm } from "react-hook-form";





function AddArea () {

    const { register, handleSubmit,  errors } = useForm();
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [group, setGroup] = useState([])


  const addarea = (data) => {
    setisBtnLoading(true)

    return fetch(`${configData.SERVER_URL}/area`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({            
          name:data.name,
          groupId:data.groupId
        })
    })
    .then((response) => response.json())
    .then((responseJson) => {

        if(responseJson.status === "success"){
            setisBtnLoading(false)
            alert(responseJson.message);
        }
        if (responseJson.status === "error") {
            setisBtnLoading(false)
            console.log(responseJson.message);
        }
    })
    .catch((error) => {
        setisBtnLoading(false)
        console.error(error);
    });

}


  const getGroup = ()=>{
       
    return fetch(`${configData.SERVER_URL}/group`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setGroup(responseJson.group);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    getGroup();
 },[]);

    return (
      <>

        <a class="nav-link" href="#"  data-toggle="modal" data-target="#exampleModal2">Add Area</a>
              


            <div className="modal fade" id="exampleModal2" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Area</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12" onSubmit={handleSubmit(addarea)}>
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Name of Area" name="name" ref={register({ required: true, })}/>
                                    {errors.name && <p>Name of Area is Required</p>}
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleFormControlSelect1">Select Group</label>
                                      <select class="form-control"  name="groupId" ref={register({ required: true, })}>
                                        {(!group.length) ? (null) : (<>
                                            {group.map(s => (
                                                <option value={s._id}>{s.name}</option>
                                            ))}
                                        </>)}
                                      </select>
                                  </div>
                                  <button  type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD AREA</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
       </>  );

  }
  


export default AddArea;
