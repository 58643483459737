import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../../config.json";
import { format } from 'date-fns' 





const AddProduct = () => {
    const [cat, setCat] = useState([])
    const [isloading, setIsloading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);

 const addProd = async(e) => {
  setisBtnLoading(true);

        e.preventDefault()

        const form = new FormData(document.getElementById("submit"))
      
            return fetch(`${configData.SERVER_URL}/product`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "x-auth-token":  window.localStorage.getItem("token")
                },
                body:form
            })
            .then((response) => response.json())
            .then((responseJson) => {
      
                if(responseJson.status === "success"){
                  setisBtnLoading(false);
                  window.$("#exampleModal3").modal("hide") 
                    alert(responseJson.message);
                }
                if (responseJson.status === "error") {
                    console.log(responseJson.message);
                }
            })
            .catch((error) => {
              setisBtnLoading(false);
                console.error(error);
            });
   
  }


  const categ = async() => {
         
    return fetch(`${configData.SERVER_URL}/category`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCat(responseJson.category);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    categ();

 },[]);


    return (
      <>

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-primary">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars"></i>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                  <a class="nav-link" href="#"  data-toggle="modal" data-target="#exampleModal3">Add Product</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12"  onSubmit={addProd} enctype="multipart/form-data" id="submit" >
                               
                                  <div class="form-group">
                                    <label for="exampleFormControlFile1">Upload Item Image</label>
                                    <input type="file" class="form-control-file" name="productImage"/>
                                  </div>
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Product Name" name="name"/>
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleFormControlSelect1">Select Item Category</label>
                                      <select class="form-control"  name="categoryId">
                                                {(!cat.length) ? (null) : (<>
                                                    {cat.map(c => (
                                                        <option value={c._id}>{c.name}</option>
                                                    ))}
                                                </>)}
                                      </select>
                                  </div>
                                  <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD PRODUCT</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
       </>  );

  }
  


export default AddProduct;
