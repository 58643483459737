import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import { format } from 'date-fns' 
import AddAdmin from "./component/AddAdmin";

const Admin = () => {
  const [user, setUser] = useState([])

  const getAdmin = async()=> {
     
    return fetch(`${configData.SERVER_URL}/admin`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.admin[0]);
        setUser(responseJson.admin);
      })
      .catch((error) => {
        console.error(error);
      });
  }

 const deleteAdmin = async(a) => {
            
  if (!window.confirm("are you sure you want to delete this admin?")) {
    return
  }

      return fetch(`${configData.SERVER_URL}/admin/delete/${a._id}`, {
          method: "delete",
          headers: {
              Accept: "application/json",
              "x-auth-token":  window.localStorage.getItem("token")
          },
      })
      .then((response) => response.json())
      .then((responseJson) => {

          if(responseJson.status === "success"){
              getAdmin();
              alert(responseJson.message);
          }
          if (responseJson.status === "error") {
              alert(responseJson.message);
          }
      })
      .catch((error) => {
          console.error(error);
      });

}


  useEffect(() => {
    getAdmin();
 },[]);

 let itemsToRender;
 if (user) {
   itemsToRender = user.map((c, index) => {
     return <>
                    <tr className="" key={index}>
                              <td></td>
                              <td>{c.fullname}</td>
                              <td>{c.email}</td>
                              <td>{c.role}</td>
                              {/* <td>{format(new Date(c.createdAt), 'MMMM, do, yyy')}</td>                              */}
                              {/* <td>
                                <span>
                                  <i className="fa fa-edit mr-2" aria-hidden="true"></i>
                                </span>
                              </td> */}
                              <td>
                                <span onClick={()=> deleteAdmin(c)}>
                                  <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                                </span>
                              </td>
                            </tr>
     </>;
   });
 } else {
   itemsToRender = "Loading...";
 }


    return (<>
    
		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
        <AddAdmin/>

        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL ADMIN</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

                  <div className="table-responsive">
                    <table className="table white black-text table-hover">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            </tr>
                        </thead>
                      <tbody>

                        
                             {itemsToRender}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        
    
    </>);

  }

export default Admin;
