
import React, {useEffect, useState} from 'react';
import Sidebar from '../Sidebar';
import configData from '../../config.json';
import {useParams} from "react-router-dom";
import { format } from 'date-fns' 

const TransactionDetials = () => {

    const [trans, setTrans] = useState([]);
    const [transItem, setTransItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [time, setTime] = useState(Date.now);

    const refId  =  window.localStorage.getItem("refNumb");
    const id =   window.localStorage.getItem("tranId");

    const getTransDetails = async() => {
        return fetch(`${configData.SERVER_URL}/transaction/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token")
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
            setTrans(responseJson.trans);
            setTime(responseJson.trans.createdAt);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      }
    
   const getTransItem = () => {

    return fetch(`${configData.SERVER_URL}/transactionItem/${refId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token")
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        setTransItem(responseJson.transItem);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

    useEffect(() => {
      const interval=setInterval(()=>{
        getTransDetails();
        getTransItem();
       },5000)
         
         
       return()=>clearInterval(interval)
    },[]);


    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                            {isLoading ?  null : <>   
                                        <div className="col-md-12 mt-2">
                                            <h4 className="title">This order is currently handled by {trans.customerCare}</h4>
                                        </div>
                                        </>}
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">TRANSACTIONS DETAILS</p>
                                        </div>

                  
          {isLoading ? <>Loading </> : <>      
                            <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card p-4" id="">
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-6">                              
                                                    <span class="text-center font-weight-bold mt-2">Transaction ID:</span>
                                                    <span class="p"> {trans.reference}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-3"></div>
                                                <div class="col-md-12 mt-5">
                                                    <span class="text-center font-weight-bold">Date:</span>
                                                    <span class="p" style={{float:"right"}}>
                                                        {format(new Date(time), 'MMMM, do, yyy')}
                                                        </span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Customers Name:</span>
                                                    <span class="p" style={{float:"right"}}>{trans.userName}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Customers Phone:</span>
                                                    <span class="p" style={{float:"right"}}>{trans.phone}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Agents Name:</span>
                                                    <span class="p" style={{float:"right"}}>{trans.agentName}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Agents Phone:</span>
                                                    <span class="p" style={{float:"right"}}>{trans.agentPhone}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Vendors Name</span>
                                                    <span class="p" style={{float:"right"}}>
                                                        {trans.vendorName}
                                                    </span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Vendors Phone</span>
                                                    <span class="p" style={{float:"right"}}>
                                                        {trans.vendorPhone}
                                                    </span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Transaction Amout:</span>
                                                    <span class="p" style={{float:"right"}}>
                                                        # {trans.amountToPay}
                                                    </span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Payment Status:</span>
                                                    <span class="p" style={{float:"right"}}>
                                                     {trans.orderPaymentStatus}
                                                    </span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12">
                                                    <span class="text-center font-weight-bold">Delivery Address:</span>
                                                    <span class="p" style={{float:"right"}}>{trans.address}</span>
                                                    <hr/>
                                                </div>
                                                <div class="col-md-12 mt-4">
                                                    <span class="text-center font-weight-bold">GRAND TOTAL:</span>
                                                    <span class="p" style={{float:"right", fontSize:"30px"}}>
                                                    # {trans.amount}
                                                    </span>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <div class="card p-4" id="">
                                            
              <div className="row">
                <div className="col-md-12 mt-3">

                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            {/* <tr>
                            <th scope="col">Users Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Vendors Name</th>
                            <th scope="col">Agents Name</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Payment Status</th>
                            </tr> */}
                        </thead>
                      <tbody>
                 {transItem.map(p => (
                            <tr className="" key={p._id}>
                              <td>
                                  <img class="img-fluid mx-auto d-block " src={`${configData.PIC_URL}/${p.productImage}`} width="100%"/></td>
                              <td>{p.productName}</td>
                              <td>#{p.amount}</td>
                              <td>{p.status}</td>                            
                            </tr>
                     ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>                                                      
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                            </div>

                            </>}




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );

}

export default TransactionDetials;