import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Sidebar'
import configData from "../../config.json";
import AddCity from "./component/AddCity";
import AddArea from "./component/AddArea";
import AddGroup from "./component/AddGroup";
import { format } from 'date-fns' 

const Area = () => {
    const [area, setArea] = useState([]);
    const [group, setGroup] = useState([]);
    const [areaName, setAreaName] = useState();
    const [areaGroup, setAreaGroup] = useState();
    const [areaId, setAreaId] = useState();
    const [isBtnLoading, setisBtnLoading] = useState(false);

  const getGroup = () => {
       
    return fetch(`${configData.SERVER_URL}/group`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setGroup(responseJson.group);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getAreas = ()=>{
       
    return fetch(`${configData.SERVER_URL}/area`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setArea(responseJson.area);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const openModal = (a) => {
      setAreaName(a.name);
      setAreaGroup(a.groupId);
      setAreaId(a._id);
      window.$("#openModal").modal("show"); 
   }


  const updateArea = () => {
    setisBtnLoading(true);

    return fetch(`${configData.SERVER_URL}/area/update/${areaId}`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({            
          name:areaName,
          groupId:areaGroup
        })
    })
    .then((response) => response.json())
    .then((responseJson) => {

        if(responseJson.status === "success"){
            setisBtnLoading(false)
            alert(responseJson.message);
        }
        if (responseJson.status === "error") {
            setisBtnLoading(false)
            console.log(responseJson.message);
        }
    })
    .catch((error) => {
        setisBtnLoading(false)
        console.error(error);
    });
  }

  useEffect(() => {
    getGroup();
    getAreas();
 },[]);


 let itemsToRender;
 if (area) {
   itemsToRender = area.map(a => {
     return <>
     
     <tr className="" key={a._id}>
                    <td></td>
                    <td>{a.name}</td>
                    <td>{a.groupName}</td>
                    <td>{a.stateName}</td>
                    <td>
                        {format(new Date(a.createdAt), 'MMMM, do, yyy')}
                    </td>
                    <td>
                        <Link onClick={()=> openModal(a)}>
                        <i className="fa fa-edit mr-2" aria-hidden="true"></i>
                        </Link>
                    </td>
                    <td>
                        <Link to={`/`}>
                        <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                        </Link>
                    </td>
                    </tr>
     </>;
   });
 } else {
   itemsToRender =  
   <div style={{justifyContent:'center', alignContent:'center', width:'100%', height:'100%'}}>
           <p>No Item Found</p>
     </div>;
 }

    return (<>
  
	<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
    <div id="content" class="p-4 p-md-5">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-primary">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars"></i>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                  <AddArea/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
        
    <div className="col-md-12 mt-4">
        <div className="row">
          <div className="col-md-12">
            <p className="title2">ALL AREA</p>
          </div>
          <div className="col-md-12">
            <div className="row">

            <div className="col-md-12 mt-5">

                <div className="table-responsive">
                <table className="table white black-text table-hover">
                <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name of Area</th>
                            <th scope="col">Name of Group</th>
                            <th scope="col">Name of State</th>
                            <th scope="col">Date</th>
                            <th scope="col">Date Created</th>
                            </tr>
                        </thead>
                    <tbody>

                          {itemsToRender}

                    </tbody>
                </table>
                </div>
            </div>

            </div>
          </div>
        </div>
      </div>

   </div>
   </div>



   <div className="modal fade" id="openModal" tabindex="-1" role="dialog"
                aria-labelledby="openModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="openModal">Add Area</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12">
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Name of Area" value={areaName}  onChange={(e) => setAreaName(e.target.value)}  name="name"/>
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleFormControlSelect1">Select Group</label>
                                      <select class="form-control"  name="groupId" value={areaGroup}   onChange={(e) => setAreaGroup(e.target.value)}>
                                        {(!group.length) ? (null) : (<>
                                            {group.map(g => (
                                                <option value={g._id}>{g.name}</option>
                                            ))}
                                        </>)}
                                      </select>
                                  </div>
                                  <button onClick={()=> updateArea()}  type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD AREA</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
   
   
    </>);

}



export default Area;