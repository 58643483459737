import React, { useState, useEffect, useStickyState } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import AddCategory from "../Admin/component/AddCategory";
import { format } from 'date-fns' 

const Category = () => {
  const [cat, setCat] = useState([])
  const [catId, setCatId] = useState();
  const [isBtnLoading, setisBtnLoading] = useState(false);
  const [image, setImage] = useState('');

  //  let { id } = useParams();

  const getCat = async()=>{
        return fetch(`${configData.SERVER_URL}/category`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
               setCat(responseJson.category);
            })
            .catch((error) => {
            console.error(error);
            });
     }

  useEffect(() => {
    getCat();
 },[]);


 const openModal = (c) => {
  setCatId(c._id);
  window.$("#catModal3").modal("show"); 
}

 const updateCat = async(e) => {

    setisBtnLoading(true);
    e.preventDefault()

    const formData = new FormData();
    formData.append('image', image);
  
        return fetch(`${configData.SERVER_URL}/category/update/${catId}`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "x-auth-token":  window.localStorage.getItem("token")
            },
            body:formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
  
            if(responseJson.status === "success"){
              setisBtnLoading(false);
  
                alert(responseJson.message);
            }
            if (responseJson.status === "error") {
                console.log(responseJson.message);
            }
        })
        .catch((error) => {
          setisBtnLoading(false);
            console.error(error);
        });


 }


      return (<>

		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
        <AddCategory/>
    
    

       {/* geting the categories */}
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL CATEGORIES</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            <tr>
                            <th scope="col"></th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Date Created</th>
                            </tr>
                        </thead>
                      <tbody>
                       {cat.map((c, index )=> (
                            <tr className="" key={index + 1}>
                              <td><img src={`${configData.PIC_URL}/${c.image}`}  class="d-inline-block img-fluid tableImg" alt="cat"/></td>
                              <td>{c.name}</td>
                              <td>{format(new Date(c.createdAt), 'MMMM, do, yyy')}</td>
                              <td onClick={() => openModal(c)}>
                                  <i className="fa fa-edit mr-2" ></i>
                              </td>
                              <td>
                                {/* <Link to={`/`}>
                                  <i className="fa fa-trash mr-2"></i>
                                </Link> */}
                              </td>
                            </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        


    <div className="modal fade" id="catModal3" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Update Category</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                  <div className="row">
                             <form class="col-12" onSubmit={updateCat} enctype="multipart/form-data" id="submit">
                                <div class="form-group">
                                       <label for="exampleFormControlFile1">Upload Category Image</label>
                                        <input type="file" class="form-control-file" name="image" onChange={(e)=> setImage(e.target.files[0])}/>
                                      </div>
                                      {/* <div class="form-group">
                                        <label>Enter Category Name</label>
                                        <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Category Name" name="name"/>
                                      </div> */}
                                      <button  type="submit" name="add" class="btn btn-warn btn-block btn-sm" id="submit" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD CATEGORY</>)}</button>
                                    </form>                                 
                                  </div>
                                </div>
                                <div className="col-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
    
         
     </> );
  
    }
export default Category;