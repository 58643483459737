import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../../config.json";
import { format } from 'date-fns';
import { useForm } from "react-hook-form";





function AddAgent () {

    const { register, handleSubmit,  errors } = useForm();
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [group, setGroup] = useState([]);

    const getGroup = ()=>{
       
      return fetch(`${configData.SERVER_URL}/group`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            setGroup(responseJson.group);
          })
          .catch((error) => {
            console.error(error);
          });
    }
  
    useEffect(() => {
      getGroup();
   },[]);

  const addAg = (data) => {
      
    setisBtnLoading(true)
  
    return fetch(`${configData.SERVER_URL}/users/register-agent`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({            
            fullname:data.fullname,      
            email:data.email,  
            phone:data.phone, 
            password:data.password,
            userGroupId: data.groupId
        })
    })
    .then((response) => response.json())
    .then((responseJson) => {

        if(responseJson.status === "success"){
            setisBtnLoading(false)
            alert(responseJson.message);
        }
        if (responseJson.status === "error") {
            setisBtnLoading(false)
            alert(responseJson.message);
        }
    })
    .catch((error) => {
        setisBtnLoading(false)
        console.error(error);
    });

}


    return (
      <>


<nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-primary">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars"></i>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" href="#"  data-toggle="modal" data-target="#exampleModal2">Add Agent</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
             


            <div className="modal fade" id="exampleModal2" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Agent</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12" onSubmit={handleSubmit(addAg)}>
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Full Name" name="fullname" ref={register({ required: true, })}/>
                                    {errors.fullname && <p>Full Name Required</p>}
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Email" name="email" ref={register({ required: true, })}/>
                                    {errors.email && <p>Email Required</p>}
                                  </div>
                                  <div class="form-group">
                                    <input type="tel" class="form-control" aria-describedby="textHelp" placeholder="Phone Number" name="phone" ref={register({ required: true, })}/>
                                    {errors.phone && <p>Phone Number is Required</p>}
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleFormControlSelect1">Select Group</label>
                                      <select class="form-control"  name="groupId" ref={register({ required: true, })}>
                                        {(!group.length) ? (null) : (<>
                                            {group.map(s => (
                                                <option value={s._id}>{s.name}</option>
                                            ))}
                                        </>)}
                                      </select>
                                  </div>
                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <p>Password Required</p>}
                            </div>
                                  <button  type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD AGENT</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               
       </>  );

  }
  


export default AddAgent;
