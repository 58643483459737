import React, {useState, useEffect} from 'react';
import $ from 'jquery';
// import {Redirect, Link, useParams} from "react-router-dom";
import configData from "../config.json";
import { useForm } from "react-hook-form";

function Sidebar() {

    

    useEffect(() => {
        var fullHeight = function () {
          $('.js-fullheight').css('height', $(window).height());
          $(window).resize(function () {
            $('.js-fullheight').css('height', $(window).height());
          });
        };
        fullHeight();
    
        $('#sidebarCollapse').on('click', function () {
          $('#sidebar').toggleClass('active');
        });
    }, []);

    const logout = () => {
            window.localStorage.clear();
            window.location = window.location.origin + "/";
        }

        // useEffect(() => {
        //     const token = window.localStorage.getItem('token');
        //     if(!token){
        //         window.location = window.location.origin + "/";
        //     }
        // })
    

  return (
   
           <nav id="sidebar">
                <div class="p-4 pt-5">
                    <a href="#" class="img logo rounded-circle mb-5 image-backg"></a>
                    <ul class="list-unstyled components mb-5">

            {/* {
              JSON.parse(window.localStorage.getItem("Admin") === "admin") && (
                <> */}
                    <li>
                        <a href="/Admin/Dashboard">Dashboard</a>
                    </li>
                    <li>
                        <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Administration</a>
                        <ul class="collapse list-unstyled" id="pageSubmenu">
                            <li>
                                <a href="/Admin/Admins">Admin</a>
                            </li>
                            <li>
                                <a href="/Admin/Location">Location</a>
                            </li>
                            <li>
                                <a href="/Admin/Area">Area</a>
                            </li>
                            <li>
                                <a href="/Admin/Category">Category</a>
                            </li>
                            <li>
                                <a href="/Admin/Product">Local Market</a>
                            </li>
                            <li>
                                <a href="/Admin/Brick-add-Morta">Brick and Mortal</a>
                            </li>
                            <li>
                                <a href="/Admin/Deals">Farm Products</a>
                            </li>
                            <li>
                                <a href="/Admin/Artisans">Artisan</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/Admin/Agents">Agents</a>
                    </li>
                    <li>
                        <a href="/Admin/Vendors">Vendors</a>
                    </li>
                    <li>
                        <a href="/Admin/Users">Users</a>
                    </li>
                    {/* </>)} */}
                    <li>
                        <a href="/Admin/Transactions">Transactions</a>
                    </li>
                    <li>
                        <a onClick={()=> logout()} style={{cursor:'pointer'}} >Logout</a>
                    </li>
                    
                
                    </ul>

                    <div class="footer">
                    {/* <p>
                        Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i class="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a>
                        </p> */}
                    </div>

                </div>
            </nav>

  );
}

export default Sidebar;
