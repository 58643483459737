import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import { format } from 'date-fns' 

const Transactions = () => {
  const [trans, setTrans] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  //  let { id } = useParams();

  const transaction = async() => {
      
    return fetch(`${configData.SERVER_URL}/transaction/success/market`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token":  window.localStorage.getItem("token")
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      setTrans(responseJson.trans);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error(error);
    });

 };

 useEffect(() => {
   
  const interval=setInterval(()=>{
    transaction();
   },3000)
    
   return()=>clearInterval(interval)

},[]);



 const viewMore = (trans) => {
    window.localStorage.setItem("refNumb", trans.reference)
    window.localStorage.setItem("tranId", trans._id)
    window.location = window.location.origin + "/Admin/TransactionDetials" 
  }


    return (
    
		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    

        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">TRANSACTIONS</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

          {isLoading ? <>Loading </> : <>
                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            <tr>
                            <th scope="col">Users Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Vendors Name</th>
                            <th scope="col">Agents Name</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Payment Status</th>
                            </tr>
                        </thead>
                      <tbody>
                 {trans.map(t => (
                            <tr className="" key={t._id}>
                              <td>{t.userName}</td>
                              <td>₦ {t.amount}</td>
                              <td>{t.vendorName}</td>
                              <td>{t.agentName}</td>
                              <td>{t.orderStatus}</td>
                              <td>{t.orderPaymentStatus}</td>
                              <td>{format(new Date(t.createdAt), 'MMMM, do, yyy')}</td>                             
                            <td onClick={() => viewMore(t)} style={{cursor:'pointer', backgroundColor:'green', color:'#fff'}}>view</td>
                            </tr>
                     ))}

                      </tbody>
                    </table>
                  </div>
                  </>}


                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        
    
    );

  }

export default Transactions;
