import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../../config.json";
import { format } from 'date-fns';
import { useForm } from "react-hook-form";





function AddCity (){
    const { register, handleSubmit,  errors } = useForm();
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [city, setCity] = useState([])

 const addC = (data) => {
   
          setisBtnLoading(true)   
            return fetch(`${configData.SERVER_URL}/state`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-auth-token":  window.localStorage.getItem("token")
                },
                body: JSON.stringify({            
                  name:data.name,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
      
                if(responseJson.status === "success"){
                    setisBtnLoading(false)
                    alert(responseJson.message);
                }
                if (responseJson.status === "error") {
                    setisBtnLoading(false)
                    console.log(responseJson.message);
                }
            })
            .catch((error) => {
                setisBtnLoading(false)
                console.error(error);
            });
   
  }



    return (
      <>

                  <a class="nav-link" href="#"  data-toggle="modal" data-target="#exampleModal1">Add City</a>
              


      <div className="modal fade" id="exampleModal1" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12" onSubmit={handleSubmit(addC)}>
                               
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Name of City" name="name" ref={register({ required: true, })}/>
                                    {errors.name && <p>Name of City is Required</p>}
                                  </div>
                                  <button type="submit"  class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD CITY</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>

           
       </>  );

  }
  


export default AddCity;
