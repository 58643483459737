import React, { useState, useEffect, useStickyState } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import AddDeals from "../Admin/component/AddDeals";
import { format } from 'date-fns' 

const Deals = () => {
  const [product, setProduct] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [dealName, setDealName] = useState();
  const [dealAmt, setDealAmt] = useState();
  const [dealId, setdealId] = useState();
  const [isBtnLoading, setisBtnLoading] = useState(false);

  const getProduct = () => {
         
    return fetch(`${configData.SERVER_URL}/deal`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setProduct(responseJson.product);
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false)
        });
  }

  useEffect(() => {
    const interval=setInterval(()=>{
      getProduct();
    },3000)
    
    return()=>clearInterval(interval)
 },[]);


 const dealDelete = (p) => {
  if (!window.confirm("are you sure?")) {
    return
  }
          
 const id = p._id;
  return fetch(`${configData.SERVER_URL}/deal/delete/${id}`, {
    method: "delete",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token":  window.localStorage.getItem("token")
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      alert(responseJson.message)
    })
    .catch((error) => {
      console.error(error);
    });
}
const openModal = (a) => {
  setDealName(a.name);
  setDealAmt(a.amount);
  setdealId(a._id);
  window.$("#openModal").modal("show"); 
}


const updateDeals = () => {
setisBtnLoading(true);

return fetch(`${configData.SERVER_URL}/deal/update/${dealId}`, {
    method: "post",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
    },
    body: JSON.stringify({            
      name:dealName,
      amount:dealAmt
    })
})
.then((response) => response.json())
.then((responseJson) => {

    if(responseJson.status === "success"){
        setisBtnLoading(false)
        alert(responseJson.message);
    }
    if (responseJson.status === "error") {
        setisBtnLoading(false)
        console.log(responseJson.message);
    }
})
.catch((error) => {
    setisBtnLoading(false)
    console.error(error);
});
}


    return (<>

		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
        <AddDeals/>
    
    

       {/* geting the categories */}
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL DEALS</p>
            </div>
            <div className="col-md-12">
              <div className="row">

              {isLoading ? <>Loading </> : <>   
                <div className="col-md-12 mt-3">

                  <div className="row">
                  {product && product.length > 0
                     ?  product.map(p => {
                        return <>
                            <div class="col-md-3 mt-2" >
                                <div class="boxShadow p-2">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <img class="img-fluid d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`} width="100%"/>
                                            </div>
                                        <div class="col-lg-12 mt-1">
                                            <h6>{p.name}</h6>
                                        </div>
                                        <div class="col-lg-12 mt-1">
                                            <h6># {p.amount}</h6>
                                        </div>
                                        <div class="col-lg-6 mt-1">
                                          <Link onClick={()=> openModal(p)}>
                                               <i className="fa fa-edit mr-2" aria-hidden="true" style={{color:'green'}}></i>
                                           </Link>
                                        </div>
                                        <div class="col-lg-6 mt-1">
                                          <Link onClick={()=> dealDelete(p)}>
                                               <i className="fa fa-trash mr-2" aria-hidden="true" style={{color:'red'}}></i>
                                           </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>})
                        : <div className="col-md-12"> 
                            <h4>No item found</h4>
                           
                           </div>}

                  </div>
                </div>
                </>}
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        


      <div className="modal fade" id="openModal" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title" id="exampleModalLabel">Add Product</h5> */}
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12" enctype="multipart/form-data" id="submit" >
                              
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Product Name" name="name"  value={dealName}  onChange={(e) => setDealName(e.target.value)} />
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Product amount" name="amount"  value={dealAmt}  onChange={(e) => setDealAmt(e.target.value)} />
                                  </div>

                                  <button onClick={()=> updateDeals()} type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD DEAL</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
        



     </> );

  }
  


export default Deals;
