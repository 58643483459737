// AddArtisan

import React, { useState, useEffect, useStickyState } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import AddArtisan from "../Admin/component/AddArtisan";
import { format } from 'date-fns' 

const Artisan = () => {
  const [artisans, setArtisans] = useState([])
  const [productImage, setProductImage] = useState('');
  const [prodId, setProdId] = useState('')
  const [city, setCity] = useState([]);
  const [type, setType] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isBtnLoading, setisBtnLoading] = useState(false);
  const [msg, setMsg] = useState("")

const createArtisanProfile = async(e) => {

      // setisBtnLoading(true);

      e.preventDefault();

      const form = new FormData(document.getElementById("submit"))
      for (const entry of form.entries()) {
        console.log(entry[0], entry[1]);
      }
    
          return fetch(`${configData.SERVER_URL}/artisan`, {
              method: "post",
              headers: {
                  Accept: "application/json",
                  "x-auth-token":  window.localStorage.getItem("token")
              },
              body:form
          })
          .then((response) => response.json())
          .then((responseJson) => {
    
              if(responseJson.status === "success"){
                setisBtnLoading(false);
                  alert(responseJson.message);
              }
              if (responseJson.status === "error") {
                  setMsg(responseJson.message);
                  console.log(responseJson.message);
              }
          })
          .catch((error) => {
            setisBtnLoading(false);
              console.error(error);
          });
 
}


const getState = async() => {
  return fetch(`${configData.SERVER_URL}/state`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
          setCity(responseJson.state);
      })
      .catch((error) => {
        console.error(error);
      });
}

useEffect(() => {
  setisBtnLoading(false);
  getState();

},[]);



const updateProd = async(e) => {
setisBtnLoading(true);

      e.preventDefault()
      
      const formData = new FormData()
      formData.append('productImage', productImage)
    
          return fetch(`${configData.SERVER_URL}/product/update/${prodId}`, {
              method: "post",
              headers: {
                  Accept: "application/json",
                  "x-auth-token":  window.localStorage.getItem("token")
              },
              body:formData
          })
          .then((response) => response.json())
          .then((responseJson) => {
    
              if(responseJson.status === "success"){
                setisBtnLoading(false);
    
                  alert(responseJson.message);
              }
              if (responseJson.status === "error") {
                  console.log(responseJson.message);
              }
          })
          .catch((error) => {
            setisBtnLoading(false);
              console.error(error);
          });
 
}

  const getArtisans = async() => {
         
    return fetch(`${configData.SERVER_URL}/artisan`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          setArtisans(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }


  const getArtCat = async() => {
         
    return fetch(`${configData.SERVER_URL}/artisanCategory/category`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          setType(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }




  useEffect(() => {
    // const interval=setInterval(()=>{
        getArtisans();
    //  },3000)
    getArtCat()
      
    //  return()=>clearInterval(interval)
 },[]);

 const editArtisans = (product) => {
     setProdId(product._id);

     window.$("#modal3").modal("show") 

 }

    return (

		<div class="wrapper d-flex align-items-stretch">
            
            <Sidebar/>
    
    
        <div id="content" class="p-4 p-md-5">
    
            <AddArtisan/>
    
    

       {/* geting the categories */}
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">Artisans</p>
              <a class="nav-link" href="#"  data-toggle="modal" data-target="#exampleModal3">Add Artisan</a>



      <div className="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Add Artisan</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <span className="">{msg}</span>
                                <div className="row">
                                <form class="col-12"  onSubmit={createArtisanProfile} enctype="multipart/form-data" id="submit" >
                                
                                    <div class="form-group">
                                        <label for="exampleFormControlFile1">Upload Image</label>
                                        <input type="file" class="form-control-file" name="profileImg"/>
                                    </div>
                                
                                    <div class="form-group">
                                        <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Full Name" name="name"/>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" aria-describedby="textHelp" placeholder="Email" name="email"/>
                                    </div>
                                    <div class="form-group">
                                        <input type="tel" class="form-control" aria-describedby="textHelp" placeholder="Phone" name="phone"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Artisan Category</label>
                                        <select class="form-control"  name="stateId">
                                                    {(!type.length) ? (null) : (<>
                                                        {type.map((t, index )=> (
                                                            <option value={t._id}>{t.name}</option>
                                                        ))}
                                                    </>)}
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">State of residence</label>
                                        <select class="form-control"  name="stateId">
                                                    {(!city.length) ? (null) : (<>
                                                        {city.map(c => (
                                                            <option value={c._id}>{c.name}</option>
                                                        ))}
                                                    </>)}
                                        </select>
                                    </div>
                                        <div class="form-group">
                                            <label for="formControlTextarea1">Address</label>
                                            <textarea class="form-control" id="formControlTextarea1" rows="3" name="address"></textarea>
                                        </div>
                                    <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>SUBMIT</>)}</button>
                                    
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               




            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

                  <div className="row">



    <div class="col-12">
		<table className="table table-image">
		  <thead>
		    <tr>
		      <th scope="col">No</th>
		      <th scope="col">Image</th>
		      <th scope="col">Name</th>
		      <th scope="col">Email</th>
		      <th scope="col">Phone</th>
		      <th scope="col">State</th>
		    </tr>
		  </thead>
		  <tbody>


        {artisans && artisans.length > 0
            ?  artisans.map((a, index) => {
            return <>
		    <tr>
		      <th scope="row">{index + 1}</th>
		      <td>
			      <img src={`${configData.PIC_URL}/${a.profileImg}`} className="img-fluid img-thumbnail tableImg" alt="Sheep"/>
		      </td>
		      <td>{a.name}</td>
		      <td>{a.email}</td>
		      <td>{a.phone}</td>
		      <td>{a.state}</td>
		    </tr>

            </>})
            : <div className="col-md-12"> 

                <h6>No item found</h6>
                
                </div>}

		  </tbody>
		</table>   
    </div>



                    {/* {product.map(p => (<>
                            <div class="col-md-3 mt-2">
                                <div class="boxShadow p-2">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <img class="img-fluid mx-auto d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`} width="100%" onClick={()=> editProduct(p)}/>
                                            </div>
                                        <div class="col-lg-12 mt-1">
                                            <h6>{p.name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>))} */}


      <div className="modal fade" id="modal4" tabindex="-1" role="dialog"
                           aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="modalLabel">Update Product</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                         <div className="modal-body">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                              <div className="row">
                                <form class="col-12"  onSubmit={updateProd} method="POST" enctype="multipart/form-data" id="submit" >
                                  <div class="form-group">
                                    <label for="exampleFormControlFile1">Upload Item Image</label>
                                    <input type="file" class="form-control-file" name="productImage"
                                    onChange={(e)=> setProductImage(e.target.files[0])}/>
                                  </div>
                                  <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD PRODUCT</>)}</button>
                                </form>
                                
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
               

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        

      );

  }
  


export default Artisan;
