import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import configData from "../../config.json";
import Sidebar from "../Sidebar";
import { format } from 'date-fns' 

const User = () => {
  const [user, setUser] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  //  let { id } = useParams();

  const getUser = () =>{

    return fetch(`${configData.SERVER_URL}/users/users`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setUser(responseJson.users);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const interval=setInterval(()=>{
        getUser();
     },3000)
      
     return()=>clearInterval(interval)
 },[]);

  const agentDelete = (u) => {
    if (!window.confirm("are you sure?")) {
      return
    }
            
   const id = u._id;
    return fetch(`${configData.SERVER_URL}/users/delete/${id}`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.message);
      })
      .catch((error) => {
        console.error(error);
      });
  }


    return (
    
		<div class="wrapper d-flex align-items-stretch">
        <Sidebar/>

        
    
    
        <div id="content" class="p-4 p-md-5">
          
    

        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-12">
              <p className="title2">ALL USERS</p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mt-3">

                {isLoading ? <>Loading </> : <>   

                  <div className="table-responsive">
                    <table className="table white black-text  table-hover">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            </tr>
                        </thead>
                      <tbody>
                 {user.map(u => (
                            <tr className="" key={u._id}>
                              <td></td>
                              <td>{u.fullname}</td>
                              <td>{u.email}</td>
                              <td>{u.phone}</td>
                              {/* <td>{format(new Date(u.createdAt), 'MMMM, do, yyy')}</td>                               */}
                              <td>{u.religiousOrganization}</td>
                              <td>
                                <Link  onClick={()=> agentDelete(u)}>
                                  <i className="fa fa-trash mr-2" aria-hidden="true" style={{color:'red'}}></i>
                                </Link>
                              </td>
                            </tr>
                     ))}

                      </tbody>
                    </table>
                  </div>

                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>


        </div>
      </div>
        
    
    );

  }

export default User;
